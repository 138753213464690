import React from 'react'
import GridList from '@material-ui/core/GridList'
import Button from '@material-ui/core/Button'
import GridListTile from '@material-ui/core/GridListTile'
import { useThemeContext } from 'context/ThemeContext'
import SEO from 'components/elements/SEO/SEO'
import { useStaticQuery, graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import CustomImage from 'components/foundations/Image/CustomImage'
import Link from 'components/foundations/Link/Link'
import { PageContent, ProductDetails } from '../ProductTemplate/ProductTemplate.styles'
import ProductName from '../ProductTemplate/ProductName'
import Description from '../ProductTemplate/Description'

export default function ConsultingTemplate({ seoKeywords, productName, description }) {
  const ConsultingQuery = Object.values(
    useStaticQuery(graphql`
      query ConsultingTQuery {
        allSitePage(filter: { path: { regex: "/^/servizi/consulenza/" } }) {
          nodes {
            path
          }
        }
      }
    `).allSitePage.nodes,
  )

  const { isMobile } = useThemeContext()

  const productURIs = ConsultingQuery.map((paths) => Object.values(paths).toString())

  function splitName(string) {
    return string.split('/')[3]
  }

  return (
    <>
      <section className="consulting closer">
        <SEO title={productName} keywords={seoKeywords} />
        <h3 className="title">Consulenza</h3>
        <CustomImage filename={'consulting.jpg'} />
        <div className="consulting-grid">
          <div className="consulting-grid-list">
            <p style={{ marginBottom: '0.5rem' }}>CATEGORIE</p>
            <div className="separator" />
            <GridList cols={isMobile ? 1 : 1} cellHeight="auto" className="gridListConsulting">
              {productURIs.map((productURL) => (
                <GridListTile className="single-consulting" key={splitName(productURL)}>
                  <Link to={productURL}>
                    <Typography className="consulting-info" component="p">
                      {splitName(productURL).replace(/-/g, ' ')}
                    </Typography>
                  </Link>
                  <hr />
                </GridListTile>
              ))}
            </GridList>
          </div>
          <div className="consulting-grid-content">
            <PageContent className="consultingTemplate">
              <ProductDetails>
                <ProductName productName={productName} />
                <Description description={description} />
                <Link to="/contatti">
                  <Button className="btn btn-primary">
                    <Typography component="h2" variant="button">
                      Contattaci
                    </Typography>
                  </Button>
                </Link>
              </ProductDetails>
            </PageContent>
          </div>
        </div>
      </section>
    </>
  )
}
